import axios from 'axios';
import { z } from 'zod';

import { PolicyNode, policyNodeSchema } from '@/services/teams/emm_policies/streamer_policies/type';
import { checkResponse } from '@/services/utils';

import { categoryEnum } from '../types';

/**
 * Streamer Preference Policy Detail API
 * Return a streamer preference policy detail and all parents policy details
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/streamer_preferences.md
 */

const getStreamerPreferencePolicyDetailUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/streamer_policy`;

const streamerPreferencePolicyDetailSchema = z.object({
  category: z.literal(categoryEnum.enum.streamer),
  platform: z.string(), // Windows, macOS, Linux, ...etc.
  current: policyNodeSchema,
  parents: z.array(policyNodeSchema),
});

export type StreamerPreferencePolicyDetail = z.infer<typeof streamerPreferencePolicyDetailSchema>;

export function getMockStreamerPolicyDetail() {
  const currentNode: PolicyNode = {
    emm_policy_id: 1,
    parent_id: 2,
    policy_items: [
      {
        id: 1,
        code: '01000001',
        operator: '+',
        value: true,
      },
      {
        id: 2,
        code: '01000002',
        operator: '-',
        value: {
          setting: null,
          os_sync: '0',
        },
      },
    ],
  };

  return checkResponse(
    new Promise((resolve) =>
      resolve({
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        data: {
          result: 20200,
          messages: [],
          data: {
            category: 'streamer',
            platform: 'Windows',
            current: currentNode,
            parents: [
              {
                emm_policy_id: 4,
                parent_id: 5,
                policy_items: [
                  {
                    id: 3,
                    code: '01000001',
                    operator: '+',
                    value: true,
                  },
                  {
                    id: 4,
                    code: '01000002',
                    operator: '+',
                    value: {
                      setting: '',
                      os_sync: '0',
                    },
                  },
                  {
                    id: 5,
                    code: '01000003',
                    operator: '+',
                    value: {
                      setting: '3',
                    },
                  },
                  {
                    id: 6,
                    code: '01000004',
                    operator: '+',
                    value: {
                      setting: '9998',
                    },
                  },
                  {
                    id: 7,
                    code: '01000005',
                    operator: '+',
                    value: {
                      setting: '1',
                    },
                  },
                  {
                    id: 8,
                    code: '01000006',
                    operator: '+',
                    value: {
                      setting: '1',
                    },
                  },
                ],
              },
              {
                emm_policy_id: 2,
                parent_id: 3,
                policy_items: [
                  {
                    id: 9,
                    code: '01000001',
                    operator: '+',
                    value: true,
                  },
                ],
              },
              {
                emm_policy_id: 3,
                parent_id: 4,
                policy_items: [
                  {
                    id: 10,
                    code: '01000001',
                    operator: '+',
                    value: true,
                  },
                  {
                    id: 11,
                    code: '01000002',
                    operator: '+',
                    value: {
                      setting: null,
                      os_sync: '0',
                    },
                  },
                ],
              },
            ],
          },
        },
      }),
    ),
    streamerPreferencePolicyDetailSchema,
  );
}

export function getStreamerPolicyDetail(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(getStreamerPreferencePolicyDetailUrl(teamId, emmPolicyId)), streamerPreferencePolicyDetailSchema);
}

export const getStreamerPreferencePolicyDetailService = {
  getUrl: getStreamerPreferencePolicyDetailUrl,
  method: 'GET',
  execute: getStreamerPolicyDetail,
};
