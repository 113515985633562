// 34XX series
export const VERSION_3480 = '3.4.8.0';

// 35XX series
export const VERSION_3503 = '3.5.0.3';
export const VERSION_3580 = '3.5.8.0';

// 36XX series
export const VERSION_3620 = '3.6.2.0';
export const VERSION_3640 = '3.6.4.0';

// 37XX series
export const VERSION_3700 = '3.7.0.0';
export const VERSION_3720 = '3.7.2.0';
