import { ReactElement } from 'react';

import { Image } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import type { TeamMemberRoleTag } from './types';

const roleIconURL: Record<TeamMemberRoleTag, string> = {
  owner: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_owner.svg',
  superAdmin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_super-admin.svg',
  admin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_admin.svg',
  groupAdmin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_group-admin.svg',
  member: '//files.splashtop.com/backend-develop/assets/ucp/icon_24x24px_role_member.svg',
};

const roleIcons: Record<TeamMemberRoleTag, ReactElement> = {
  owner: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#F8C81C" />
      <path
        d="M6.29059 7.5498L7.42353 13.5921C7.46787 13.8286 7.67436 14 7.91496 14H16.085C16.3256 14 16.5321 13.8286 16.5765 13.5921L17.7094 7.5498C17.7993 7.07018 17.2193 6.759 16.8694 7.09916L14.8254 9.08643C14.6031 9.30253 14.2398 9.26637 14.0645 9.0107L12.4124 6.60136C12.2137 6.31172 11.7863 6.31172 11.5876 6.60136L9.93552 9.0107C9.76021 9.26637 9.39689 9.30253 9.17462 9.08643L7.13056 7.09916C6.78068 6.759 6.20066 7.07018 6.29059 7.5498Z"
        fill="white"
      />
      <rect x="7" y="15" width="10" height="2" rx="0.3" fill="white" />
    </svg>
  ),
  superAdmin: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#8942E4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3241 4.93942L9.74507 8.28498C9.63527 8.51767 9.42296 8.6789 9.17736 8.71615L5.64638 9.25268C5.02786 9.34642 4.78109 10.141 5.22848 10.5972L7.78348 13.2013C7.96104 13.3824 8.04222 13.6435 8.00017 13.8991L7.39712 17.5763C7.31331 18.0859 7.70055 18.5 8.14006 18.5C8.25599 18.5 8.37544 18.471 8.49108 18.4075L11.6491 16.6715C11.7589 16.6113 11.8793 16.5811 11.9999 16.5811C12.1205 16.5811 12.2411 16.6113 12.3509 16.6715L15.5089 18.4075C15.6246 18.471 15.7437 18.4997 15.8597 18.4997C16.2992 18.4997 16.6864 18.0859 16.6029 17.5763L15.9998 13.8991C15.9578 13.6435 16.039 13.3824 16.2165 13.2013L18.7715 10.5972C19.2189 10.1413 18.9722 9.34673 18.3533 9.25268L14.8227 8.71615C14.5771 8.6789 14.3647 8.51767 14.2549 8.28498L12.6759 4.93942C12.5375 4.64627 12.2685 4.5 11.9999 4.5C11.7309 4.5 11.4622 4.64627 11.3241 4.93942Z"
        fill="white"
      />
    </svg>
  ),
  admin: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#054BBF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7892 6.5C15.6106 6.1906 15.2805 6 14.9232 6H9.07792C8.72066 6 8.39053 6.1906 8.2119 6.5L5.28925 11.5622C5.11062 11.8716 5.11062 12.2528 5.28925 12.5622L8.2119 17.6244C8.39053 17.9338 8.72066 18.1244 9.07792 18.1244H14.9232C15.2805 18.1244 15.6106 17.9338 15.7892 17.6244L18.7119 12.5622C18.8905 12.2528 18.8905 11.8716 18.7119 11.5622L15.7892 6.5ZM12.0006 14C13.1051 14 14.0006 13.1046 14.0006 12C14.0006 10.8954 13.1051 10 12.0006 10C10.896 10 10.0006 10.8954 10.0006 12C10.0006 13.1046 10.896 14 12.0006 14Z"
        fill="white"
      />
    </svg>
  ),
  groupAdmin: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#12DED2" />
      <path
        d="M11.134 6.5C11.5189 5.83333 12.4811 5.83333 12.866 6.5L18.0622 15.5C18.4471 16.1667 17.966 17 17.1962 17H6.80385C6.03405 17 5.55292 16.1667 5.93782 15.5L11.134 6.5Z"
        fill="white"
      />
    </svg>
  ),
  member: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#64E1F6" />
      <path
        d="M12 17C9.16667 17 7 14.8333 7 12C7 9.16667 9.16667 7 12 7C14.8333 7 17 9.16667 17 12C17 14.8333 14.8333 17 12 17Z"
        fill="white"
      />
    </svg>
  ),
};

type TeamMemberRoleIconProps = {
  roleTag: TeamMemberRoleTag;
};

export const TeamMemberRoleIcon = ({ roleTag }: TeamMemberRoleIconProps) => {
  if (featureControl.getToggle('PCP_460__User_Management')) {
    return roleIcons[roleTag];
  }

  const imageURL = roleIconURL[roleTag];
  return <Image boxSize="24px" alt={`team member role ${roleTag}`} src={imageURL} />;
};
