/**
 *  @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1176109287/User+Info+Query+API
 */
import axios from 'axios';
import { z } from 'zod';

import { TeamKind } from '@/models/TeamInformation/types';
import { isValidateDateTime } from '@/utils/datetime';

import { checkResponse } from '../utils';

const method = 'POST';

const getUrl = () => '/api/web/v1/users/info_query';

const execute = <Q extends QueryMap>(query: Q) => {
  return checkResponse(axios.request({ method, url: getUrl(), data: query }), generateSchema(query));
};

export const infoQueryService = {
  method,
  getUrl,
  execute,
} as const;

const LicenseInfoSchema = z
  .object({
    end_users: z.number(),
    technicians: z.number(),
    end_users_count: z.number(),
    technicians_count: z.number(),
    occupied_end_users_count: z.number(),
    occupied_technicians_count: z.number(),
  })
  .nullable();

const RolesSchema = z.enum(['owner', 'manager', 'member']);

const MemberStatusSchema = z.enum(['disabled', 'enabled', 'inviting', 'decline', 'quit']);

const GroupScopeSchema = z.enum(['all', 'part']);

const IsResellSchema = z.boolean();

const MemberIdSchema = z.number();

export function generateSchema<Q extends QueryMap, T extends TeamKind>(query: Q) {
  return z.custom<Record<T, TeamKindResult<Q>>>((data: any) => {
    const typedData = data as Record<T, TeamKindResult<Q>>;
    const teamKindResults: Array<any> = Object.values(typedData);
    if (teamKindResults.length === 0) {
      return false;
    }
    return teamKindResults.every((teamKindResult) => {
      let result = true;
      if (query.seat_permissions) {
        result =
          result &&
          teamKindResult.seat_permissions &&
          query.seat_permissions.every((seatPermission) => seatPermission in teamKindResult.seat_permissions);
      }

      if (query.team_permissions) {
        result =
          result &&
          teamKindResult.team_permissions &&
          query.team_permissions.every((teamPermission) => teamPermission in teamKindResult.team_permissions);
      }

      if (query.team_member_permissions) {
        result =
          result &&
          teamKindResult.team_member_permissions &&
          query.team_member_permissions.every((teamPermission) => teamPermission in teamKindResult.team_member_permissions);
      }

      if (query.team_info) {
        result = query.team_info.every((teamInfo) => {
          switch (teamInfo) {
            case 'license_info': {
              return LicenseInfoSchema.safeParse(teamKindResult.team_info.license_info).success;
            }
            case 'expires_at': {
              return isValidateDateTime(teamKindResult.team_info.expires_at);
            }
            case 'trial_premium_expired_at': {
              return (
                teamKindResult.team_info.trial_premium_expired_at === null ||
                isValidateDateTime(teamKindResult.team_info.trial_premium_expired_at)
              );
            }
            case 'trial_emm_expired_at': {
              return (
                teamKindResult.team_info.trial_emm_expired_at === null || isValidateDateTime(teamKindResult.team_info.trial_emm_expired_at)
              );
            }
            case 'is_expired':
            case 'upsell_premium':
            case 'can_upgrade_premium':
            case 'can_current_user_upgrade_premium':
            case 'can_trial_premium':
            case 'can_current_user_trial_premium':
            case 'upsell_emm':
            case 'can_buy_emm':
            case 'can_current_user_buy_emm':
            case 'can_trial_emm':
            case 'can_current_user_trial_emm':
              return teamInfo in teamKindResult.team_info;
            default: {
              const error: never = teamInfo;
              throw new Error(error);
            }
          }
        });
      }

      if (query.team_member_info) {
        result =
          result &&
          teamKindResult.team_member_info &&
          query.team_member_info.every((teamMemberInfo) => {
            switch (teamMemberInfo) {
              case 'role': {
                return RolesSchema.safeParse(teamKindResult.team_member_info.role).success;
              }
              case 'member_status': {
                return MemberStatusSchema.safeParse(teamKindResult.team_member_info.member_status).success;
              }
              case 'group_scope': {
                return GroupScopeSchema.safeParse(teamKindResult.team_member_info.group_scope).success;
              }
              case 'is_resell': {
                return IsResellSchema.safeParse(teamKindResult.team_member_info.is_resell).success;
              }
              case 'member_id': {
                return MemberIdSchema.safeParse(teamKindResult.team_member_info.member_id).success;
              }
              default: {
                const error: never = teamMemberInfo;
                throw new Error(error);
              }
            }
          });
      }

      return result;
    });
  });
}

// TODO: Complete the query type
export type QueryMap = {
  team_info?: ReadonlyArray<TeamInfoKey>;
  seat_permissions?: ReadonlyArray<SeatPermissionKey>;
  team_permissions?: ReadonlyArray<TeamPermissionKey>;
  team_member_info?: ReadonlyArray<TeamMemberInfoKey>;
  team_member_permissions?: ReadonlyArray<TeamMemberPermissionKey>;
};

type SeatPermissionKey =
  | 'file_transfer' // 非直接取用 DB 的 feature_set
  | 'remote_print'
  | 'off_session_file'
  | 'one_to_many'
  | 'command_prompt'
  | 'ssh_computer'
  | 'premier_pack'
  | 'sso'
  | 'off_session_chat'
  | 'access_schedule'
  | 'sos_service_desk'
  | 'sos_service_desk_psa'
  | 'vulnerability_score'
  | 'restrict_pcp_ip'
  | 'restrict_src_ip'
  | 'web_src'
  | 'open_api_token'
  | 'sos_web_src'
  | 'relay_recording'
  | 'sos_relay_recording'
  | 'macaddr_whitelist'
  | 'premium_tool'
  | 'watermark'
  | 'sos_watermark'
  | 'session_recording'
  | 'sos_session_recording'
  | 'sos_service_desk_premium_tool'
  | 'license_type'
  | 'attended_access' // 非直接取用 DB 的 feature_set
  | 'unattended_ac' // 非直接取用 DB 的 feature_set
  | 'granular_control' // 非直接取用 DB 的 feature_set
  | 'file' // 非直接取用 DB 的 feature_set, file_transfer || off_session_file
  | 'chat_transcript'; // 非直接取用 DB 的 feature_set

type TeamPermissionKey =
  | 'access_schedule'
  | 'alert_profile' // 非單憑 team.settings 判斷
  | 'allow_src_version_control'
  | 'allow_srs_version_control'
  | 'antivirus'
  | 'attended_access' // 非單憑 team.settings 判斷
  | 'bitdefender'
  | 'bitdefender2'
  | 'blank_computer_list'
  | 'command_prompt'
  | 'create_group_admin'
  | 'display_group_to_member'
  | 'emm_os'
  | 'emm_os_opportunities'
  | 'emm_patch'
  | 'emm_patch_opportunities'
  | 'event_logs'
  | 'extra_src_management'
  | 'file' // 非單憑 team.settings 判斷
  | 'file_transfer'
  | 'force_admin_2step_auth'
  | 'force_member_2step_auth'
  | 'inventory'
  | 'macaddr_whitelist'
  | 'manage_infra_gen'
  | 'member_fetch_computer_notes' // 非單憑 team.settings 判斷
  | 'member_list' // 非單憑 team.settings 判斷
  | 'member_reboot_computer' // 非單憑 team.settings 判斷
  | 'member_reboot_computer_options' // 非單憑 team.settings 判斷
  | 'mobile_streamer'
  | 'multiple_groups'
  | 'off_session_chat'
  | 'off_session_file'
  | 'one_to_many' // 非單憑 team.settings 判斷
  | 'one_to_many_basic'
  | 'open_api_token'
  | 'os_opportunities'
  | 'pref_policy_security'
  | 'preference_policy'
  | 'premier_pack'
  | 'premium_tool' // 非單憑 team.settings 判斷
  | 'rdp_computer'
  | 'relay_recording'
  | 'relay_recording_access'
  | 'remote_print'
  | 'remote_reboot'
  | 'restrict_pcp_ip'
  | 'restrict_src_ip'
  | 'smart_action'
  | 'sos_relay_recording'
  | 'sos_relay_recording_access'
  | 'sos_service_desk'
  | 'sos_service_desk_premium_tool' // 非單憑 team.settings 判斷
  | 'sos_service_desk_psa'
  | 'sos_sso'
  | 'sos_ticket_session'
  | 'sos_web_src'
  | 'ssh_computer'
  | 'sso'
  | 'vnc_computer'
  | 'vulnerability_score'
  | 'wake_on_lan'
  | 'web_src';

type TeamMemberPermissionKey =
  | 'access_schedule'
  | 'allow_src_version_control'
  | 'allow_srs_version_control'
  | 'antivirus'
  | 'attended_access' // 非從 granular_control 取得
  | 'bitdefender'
  | 'bitdefender2'
  | 'command_prompt'
  | 'create_group_admin'
  | 'emm_os'
  | 'emm_os_opportunities' // 沒有 granular control
  | 'emm_patch'
  | 'emm_patch_opportunities'
  | 'event_logs'
  | 'extra_src_management'
  | 'file'
  | 'file_transfer'
  | 'force_admin_2step_auth'
  | 'force_member_2step_auth'
  | 'grant_granular_control'
  | 'inventory'
  | 'invite_member'
  | 'license_type'
  | 'macaddr_whitelist'
  | 'manage_infra_gen'
  | 'member_listrdp_computer'
  | 'mobile_streamer'
  | 'multiple_groups'
  | 'off_session_chat'
  | 'off_session_file'
  | 'one_to_many' // 非從 granular_control 取得
  | 'one_to_many_basic'
  | 'open_api_token'
  | 'os_opportunities' // 同時考慮 emm os 或 premier_pack 有效就可以使用
  | 'pref_policy_security'
  | 'preference_policy'
  | 'premium_tool'
  | 'relay_recording'
  | 'relay_recording_access' // 非從 granular_control 取得, 根據 seat_permissions + team settings 設定決定
  | 'remote_print'
  | 'remote_reboot'
  | 'restrict_pcp_ip'
  | 'restrict_src_ip'
  | 'smart_action'
  | 'sos_relay_recording'
  | 'sos_relay_recording_access' // 非從 granular_control 取得, 根據 seat_permissions + team settings 設定決定
  | 'sos_service_desk'
  | 'sos_service_desk_premium_tool'
  | 'sos_service_desk_psa'
  | 'sos_sso'
  | 'sos_ticket_session'
  | 'sos_web_src'
  | 'ssh_computer'
  | 'sso'
  | 'sso_management' // 非從 granular_control 取得
  | 'super_admin' // 非從 granular_control 取得
  | 'super_admin_or_technician_manager' // 非從 granular_control 取得
  | 'team_settings_management' // 非從 granular_control 取得
  | 'technician_manager' // 非從 granular_control 取得
  | 'user_management' // 非從 granular_control 取得
  | 'vnc_computer'
  | 'vulnerability_score'
  | 'wake_on_lan'
  | 'web_src';

type TeamInfoKey =
  | 'license_info'
  | 'expires_at'
  | 'is_expired'
  // Premium upsell related
  /** Whether should upsell premium or not */
  | 'upsell_premium'
  /** Whether the current team can upgrade premium or not */
  | 'can_upgrade_premium'
  /** Whether the current user can upgrade premiun or not */
  | 'can_current_user_upgrade_premium'
  /** Whether the current team can trial premium or not */
  | 'can_trial_premium'
  /** Whether the current user can trial premium or not */
  | 'can_current_user_trial_premium'
  /** The expired time to premium, format: `yyyy-mm-dd hh:mm:ss` */
  | 'trial_premium_expired_at'
  // Emm upsell related
  /** Whether should upsell emm or not */
  | 'upsell_emm'
  | 'can_buy_emm'
  | 'can_current_user_buy_emm'
  | 'can_trial_emm'
  | 'can_current_user_trial_emm'
  | 'trial_emm_expired_at';
type TeamMemberInfoKey = 'role' | 'member_status' | 'group_scope' | 'is_resell' | 'member_id';

type TeamKindResult<Q extends QueryMap> = SeatPermissionResult<Q> &
  TeamInfoResult<Q> &
  TeamPermissionResult<Q> &
  TeamMemberInfoResult<Q> &
  TeamMemberPermissionResult<Q>;

type SeatPermissionResult<Q extends QueryMap> = Q['seat_permissions'] extends Array<SeatPermissionKey>
  ? { seat_permissions: Record<Q['seat_permissions'][number], boolean> }
  : { seat_permissions?: never };

type TeamPermissionResult<Q extends QueryMap> = Q['team_permissions'] extends Array<TeamPermissionKey>
  ? { team_permissions: Record<Q['team_permissions'][number], boolean> }
  : { team_permissions?: never };

type TeamMemberInfoResult<Q extends QueryMap> = Q['team_member_info'] extends Array<TeamMemberInfoKey>
  ? {
      team_member_info: {
        [K in Q['team_member_info'][number]]: K extends 'role'
          ? z.infer<typeof RolesSchema>
          : K extends 'member_status'
          ? z.infer<typeof MemberStatusSchema>
          : K extends 'group_scope'
          ? z.infer<typeof GroupScopeSchema>
          : K extends 'is_resell'
          ? z.infer<typeof IsResellSchema>
          : K extends 'member_id'
          ? z.infer<typeof MemberIdSchema>
          : never;
      };
    }
  : { team_member_info?: never };

export type TeamMemberPermissionResult<Q extends QueryMap> = Q['team_member_permissions'] extends Array<TeamMemberPermissionKey>
  ? { team_member_permissions: Record<Q['team_member_permissions'][number], boolean> }
  : { team_member_permissions?: never };

export type TeamInfoResult<Q extends QueryMap> = Q['team_info'] extends Array<TeamInfoKey>
  ? {
      team_info: Record<
        Q['team_info'][number],
        Q['team_info'][number] extends 'license_info'
          ? z.infer<typeof LicenseInfoSchema>
          : Q['team_info'][number] extends 'expires_at'
          ? string
          : Q['team_info'][number] extends 'trial_premium_expired_at' | 'trial_emm_expired_at'
          ? null | string
          : boolean
      >;
    }
  : { team_info?: never };
